import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Ouvrir un compte bancaire - comment accéler la procédure des banques ?</title>
                <meta name="description" content="
              Ouvrir un compte bancaire - comment accéler la procédure des banques ?" />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/academie/`} style={{color: 'black'}}>Banque Académie</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Ouvrir un compte bancaire en ligne – comment faire ?</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Ouvrir un compte bancaire en ligne – comment faire ?</Title>
			<StaticImage
		  	src='../../images/ouvrir-un-compte-en-banque.jpeg'
			width={1200}
			alt='Tarifs des banques'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
			<p>L’ouverture d’un compte bancaire est indispensable, ne serait-ce que pour recevoir de l’argent ou effectuer des paiements. Avec le développement du numérique et l’émergence de banques nouvelle génération, cette démarche est de plus en plus simplifiée.
			</p>
<h2>Ouvrir un compte dans une banque traditionnelle</h2>
<p>Pour ouvrir un compte au sein d’une banque de réseau, un particulier dispose parfois de plusieurs possibilités : directement en agence ou en ligne pour les établissements mettant en place ce service. Toutefois, la démarche peut être relativement longue puisqu’elle implique généralement une prise de rendez-vous ou un envoi de justificatifs qui devront être traités par la banque. Parmi les documents à fournir à l’établissement :
</p>
<ul>
	<li>Pièce d’identité (carte d’identité, passeport,…)</li>
	<li>Justificatif de domicile récent (facture EDF, quittance de loyer,…)</li>
	<li>Attestation de domiciliation, si hébergement chez un tiers</li>
	<li>Certaines banques pourront également demander des pièces annexes telles que bulletins de salaire ou avis d’imposition.</li>

</ul>
<p>Une convention de compte recensant les conditions et modalités de fonctionnement du compte bancaire sera ensuite signée entre le client et la banque. Quant aux moyens de paiement, ils seront délivrés suite à une vérification par l’établissement bancaire du fichier des incidents de paiement de la Banque de France.</p>

<h2>
Ouvrir de compte bancaire dans une banque en ligne</h2><p>
L’ouverture d’un compte bancaire chez une banque en ligne est simplifiée et plus rapide puisqu’elle se fait uniquement sur internet. Si les documents à fournir sont similaires à ceux des banques traditionnelles, l’ouverture du compte dans ces établissements est souvent soumise à des conditions de revenus et son activation à un versement initial. Par ailleurs, il est souvent possible de profiter d’une somme offerte pour une ouverture du compte.
</p><p>
Après avoir comparé les banques, il suffit de se rendre sur le site de l’établissement pour y remplir un formulaire et de renvoyer par courrier ou email les documents. Enfin, le client procédera à l’activation par le versement du montant demandé sur le compte.
</p><h2>
Ouvrir un compte dans une néo-banque</h2><p>
En quelques minutes il est possible d’ouvrir un compte chez une banque mobile via une application ou sur le site respectif. Une fois l’application téléchargée, le consommateur remplit un formulaire afin de renseigner son nom, sa date et son lieu de naissance ainsi que sa nationalité, une adresse postale, une adresse email et un numéro de téléphone. Les pièces justificatives demandées, notamment une pièce d’identité; seront scannées avant de pouvoir recevoir la carte associée au compte (si celle-ci n’a pas été obtenue par l’achat d’un coffret comme pour un compte C-Zam ou Nickel).
</p>	</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
